// contactUsData.js

export const contactUsData = {
  banner: {
    header: "Contact us",
    title: "Learn more about Rooya AI-telematics",
    description:
      "Reach out to us today to discuss your needs and discover how we can transform your fleet operations",
    contact: [
      {
        title: "Phone",
        description: "+966 11 425 5665",
        icon: "/img/map-pin.svg",
        colour: "#0DAB724D"
      },
      {
        title: "Email",
        description: "support@rooya.ai",
        icon: "/img/e-mail.svg",
        colour: "#FDB12D33"
      },
      {
        title: "Support hours",
        description: "Sunday to Thursday, 9AM to 6PM (GMT+3)",
        icon: "/img/clock.svg",
        colour: "#F1585333"
      }
    ]
  },

  talk: {
    info: {
      title: "We are here to help",
      description: "We are here to help you optimise your fleet operations and enhance safety!",
      listDescription: "Get in touch with us for more information or:",
      listItems: [
        "Request a demo",
        "Speak to our support team"
      ],
      listImg: "/img/green-tick.svg",
      contactDetails: [
        {
          label: "Email",
          value: "contact@rooya.ai"
        },
        {
          label: "Phone number",
          value: "+966 11 425 5665"
        },
        {
          label: "Address",
          value: "Riyadh-al-Olaya-Tahlia Street-Duaithar Compound, Kingdom of Saudi Arabia"
        }
      ]
    },
    form: {
      name:{
        label: "Your name*",
        placeholder: "What is your name?",
        type: "input"
      },
      email:{
        label: "Email address*",
        placeholder: "What is your contact email?",
        type: "input"
      },
      message:{
        label: "Write your message*",
        placeholder: "How can we assist you?",
        type: "textarea"
      },
      checkbox: {
        label: "Yes, I want to receive latest updates via the email",
        type: "checkbox"
      },
      submitButton: {
        text: "Send message",
        type: "button"
      },
      tandc: {
        text: "By clicking Send message you agree to the ",
        tc: "Terms and Conditions"
      }
    }
  }
};
